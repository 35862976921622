import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngVrGamePage = loadable(() => import("../components/VrGamePage/english.js"));
const ArabicVrGamePage = loadable(() => import("../components/VrGamePage/arabic.js"));
const ChineseVrGamePage = loadable(() => import("../components/VrGamePage/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section-10.webp",
            "name": "The Leading VR Game Development Company",
            "description": "Softtik is a leading VR game development company that continuously strives to provide top-tier services and innovative solutions to its clients.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.7",
                "reviewCount": "116"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "What does a VR game development company do?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p> VR game development companies try to create a virtual replica of the real world using many visualization and development tools. <b>Softtik</b> is one of the leading companies providing such solutions.</p>"
                }
            }, {
                "@type": "Question",
                "name": "How much does it cost to hire a team of game developers?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>The cost of hiring a team of game developers depends on the features and functionalities required for game development. Please reach out to us and submit your feature requirement to know the estimated cost.</p>"
                }
            }, {
                "@type": "Question",
                "name": "Is VR game development profitable?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>VR gaming is the next big thing in the gaming sector due to its immersive and engaging experience. The technology has crossed the market cap of <b>$10 billion</b> in just a few years.</p>"
                }
            }
            ]
        }
    ]
}

export class VrGamePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English",
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        // else if (region == 'sa') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/vr-game-development-services/"
                        title="VR Game Development Company - Softtik Technologies"
                        description="Softtik is a leading VR game development company that continuously strives to provide top-tier services and innovative solutions to its clients"
                    />
                    <main onWheel={this.onScroll}>
                        <div id="wrapper">
                            <div className={`collection-page metaverse-token-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicVrGamePage />
                                    : lang == "China"
                                        ? <ChineseVrGamePage />
                                        : <EngVrGamePage />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default VrGamePage;